const UA = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 20"
            width="30"
            height="20"
        >
            <rect width="30" height="10" fill="#005BBF" />
            <rect width="30" height="10" y="10" fill="#FFD500" />
        </svg>
    );
};

export default UA;

const Loading = ({ loading }) => {
    return (
        <div
            className="chat-bubble"
            style={{
                opacity: loading ? '1' : '0',
                height: loading ? 'auto' : '1px',
            }}
        >
            <div className="typing">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    );
};

export default Loading;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import Loading from './Loading.js';
import DedSoroban from './ded.png';
import UA from './UA.jsx';
const API_URL = 'https://chat-api.dedsoroban.com';
// const API_URL = 'http://localhost:5001';
import Typewriter from 'typewriter-effect';

function App() {
    const [message, setMessage] = useState('');
    const [uuid, setUuid] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const variableType = useRef('');

    const fetchInitialMessage = async () => {
        const res = await axios.get(
            `${API_URL}/initial-message?type=${variableType.current}`
        );

        setChatHistory((prevHistory) => [
            ...prevHistory,
            { message: res.data.message, from: 'DED', date: +new Date() },
        ]);
        setUuid(res.data.uuid);
        setLoading(false);
    };

    const getParams = () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const type = params.get('type');
            variableType.current = type || '1';
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getParams();
        fetchInitialMessage();
    }, []);

    const toTheBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        toTheBottom();
    }, [chatHistory]);

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setChatHistory((prevHistory) => [
            ...prevHistory,
            { message, from: 'ME', date: +new Date() },
        ]);
        setLoading(true);
        toTheBottom();
        const res = await axios.post(
            `${API_URL}/message?type=${variableType.current}`,
            { message, uuid }
        );
        setChatHistory((prevHistory) => [
            ...prevHistory,
            { message: res.data.response, from: 'DED', date: +new Date() },
        ]);
        setLoading(false);
    };

    const handleClearChat = async () => {
        setChatHistory([]);
        setLoading(true);
        const res = await axios.get(
            `${API_URL}/initial-message?type=${variableType.current}`
        );
        setChatHistory((prevHistory) => [
            ...prevHistory,
            { message: res.data.message, from: 'DED', date: +new Date() },
        ]);
        setUuid(res.data.uuid);
        setLoading(false);
    };

    const DedSorobanIcon = () => {
        return (
            <img
                alt={'Ded Soroban'}
                width={20}
                height={20}
                className="absolute top-5 left-3"
                src={DedSoroban}
            />
        );
    };

    return (
        <>
            <main className="flex max-w-6xl mx-auto items-center justify-center md:p-12 p-3 ">
                <div className="flex flex-col gap-12">
                    <h1 className="text-gray-200 font-extrabold md:text-6xl text-3xl text-center md:mt-1 mt-3">
                        Соробан відповідь
                        <span
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginLeft: '0.5rem',
                            }}
                        >
                            <UA />
                        </span>
                    </h1>

                    <div className={'relative md:max-w-2xl md:pb-40 pb-16'}>
                        <div className="pb-3">
                            {chatHistory
                                .sort((a, b) => a.date - b.date)
                                .map((data, index) => (
                                    <div className="py-2" key={index}>
                                        <div
                                            className="relative leading-7 w-full text-gray-200 rounded-md bg-gray-700 p-4 pl-11 pr-5"
                                            style={{ minHeight: '60px' }}
                                        >
                                            {(data.from === 'DED' && (
                                                <DedSorobanIcon />
                                            )) || (
                                                <span className="absolute flex items-center justify-center text-center top-5 left-3 text-xs w-5 h-5 bg-gray-500 rounded-full">
                                                    Я
                                                </span>
                                            )}
                                            {/*<h3 className="text-1xl text-gray-400 mb-2">{data.from === "DED" ? <DedSorobanIcon/> : data.from}</h3>*/}
                                            {/*<Typewriter*/}
                                            {/*    options={{*/}
                                            {/*        strings: ,*/}
                                            {/*        autoStart: true,*/}
                                            {/*        delay: 5,*/}
                                            {/*        cursor: '',*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            {data.message}
                                        </div>
                                    </div>
                                ))}
                            <Loading loading={loading} />
                        </div>
                    </div>
                </div>
            </main>

            <form
                onSubmit={handleChatSubmit}
                className="fixed bottom-0 w-full mt-3 bg-gray-700"
            >
                <div className="flex max-w-6xl mx-auto items-center justify-center md:py-12 p-2">
                    <div className="relative w-full">
                        <div className="flex items-center">
                            <input
                                className="p-4 pr-12 rounded-md bg-gray-800 text-white w-full"
                                placeholder="Поставити питання тут"
                                name="prompt"
                                required
                                value={message}
                                onChange={handleChange}
                                style={{ outline: 'none' }}
                            />
                            <button
                                type="button"
                                onClick={handleClearChat}
                                className="ml-6 text-white focus:outline-none"
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="h-4 w-4"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                    ></line>
                                    <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                    ></line>
                                </svg>
                            </button>
                        </div>
                        <button
                            type="submit"
                            className="absolute top-1/2 right-14 transform -translate-y-1/2 text-white focus:outline-none"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="none"
                                className="h-4 w-4 m-1 md:m-0"
                                strokeWidth="2"
                            >
                                <path
                                    d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default App;
